<section class="app-activities-list subactivities">
  <nz-table
    *ngIf="isExistActivity()"
    nzTableLayout="fixed"
    nzSize="small"
    nzTemplateMode
    class="subactivities__table"
    nzBreakpoint="xxl"
  >
    <thead>
      <tr>
        <th id="arronDownIcon" nzWidth="6%"></th>
        <th id="title" nzWidth="30%">
          {{ "activities.title" | translate }}
        </th>
        <th id="responsible" nzWidth="20%">
          <ng-container *ngIf="titleResponsibleFor === 'edit-processv2'">
            {{ "activities.responsible-for" | translate }}
          </ng-container>
          <ng-container *ngIf="titleResponsibleFor !== 'edit-processv2'">
            {{ "activities.responsible-for-subactivity" | translate }}
          </ng-container>
        </th>
        <th id="finalDate" nzWidth="18%">
          {{ "activities.final-date" | translate }}
        </th>
        <th id="status" nzWidth="20%">
          {{ "activities.act-status" | translate }}
        </th>
        <th id="currentIcon" nzWidth="4%"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="isExistActivity()">
        <ng-container *ngFor="let activity of activities; let i = index">
          <tr>
            <td>
              <div
                class="expand-row"
                (click)="toggle(activity)"
                [ngClass]="{ 'rotate-invert90': !activity.expanded }"
                *ngIf="activity.children?.length"
              >
                <img
                  src="/assets/icons/icon_arrow_down_small_green.svg"
                  alt="Expandir"
                />
              </div>
            </td>
            <td (click)="goToActivity(activity.id)" class="go-to-activity">
              <app-short-text-hint
                [originalText]="activity.title"
                [maxChars]="30"
                class="subactivities__table__body__title"
              >
                {{ activity.title }}
              </app-short-text-hint>
            </td>

            <td>{{ activity.responsibleUser }}</td>
            <td>
              {{
                activity.conclusionDate
                  ? (activity.conclusionDate | date : "dd/MM/yyyy")
                  : " - "
              }}
            </td>
            <td>
              <app-badge [badgeText]="activity.status"></app-badge>
            </td>
            <td *ngIf="cadActivityDelete">
              <div
                class="child act-actions"
                nz-popover
                nzPopoverTrigger="click"
                [nzPopoverContent]="popDelete"
                nzPopoverPlacement="leftBottom"
              >
                <app-svg-icon
                  icon="icon_more_vertical"
                  [current]="true"
                ></app-svg-icon>
                <ng-template #popDelete class="whiteDropdown">
                  <span class="icon">
                    <app-svg-icon
                      icon="trash_document-icon"
                      [current]="true"
                      class="svg__icon"
                    >
                    </app-svg-icon>
                    <button
                      class="btn__excluir"
                      (click)="deleteActivity(activity.id)"
                    >
                      {{ "commons.delete" | translate }}
                    </button>
                  </span>
                </ng-template>
              </div>
            </td>
          </tr>
          <tr
            *ngIf="
              activity.children !== null &&
              activity.children !== undefined &&
              activity.children?.length &&
              activity.expanded
            "
          >
            <td></td>
            <td (click)="goToActivity(activity.id)" class="go-to-activity">
              <div class="child-title">
                <span class="child-title-space"></span>
                <app-short-text-hint
                  [originalText]="activity.children[0].title"
                  [maxChars]="30"
                >
                  {{ activity.children[0].title }}
                </app-short-text-hint>
                <!-- verificar aqui o nivel que esta -->
                <span *ngIf="activity.children?.length">
                  <div
                    class="hidden-childs txt-dark4"
                    *ngIf="activity.children.length > 1"
                  >
                    &nbsp;+{{ activity.children.length - 1 }}
                  </div>
                </span>
              </div>
            </td>
            <td>{{ activity.children[0].responsibleUser }}</td>
            <td>{{ activity.children[0].dueDate }}</td>
            <td>
              <app-badge [badgeText]="activity.children[0].status"></app-badge>
            </td>
            <td *ngIf="cadActivityDelete">
              <div
                class="child act-actions"
                nz-popover
                nzPopoverTrigger="click"
                [nzPopoverContent]="popDelete"
                nzPopoverPlacement="leftBottom"
              >
                <app-svg-icon
                  icon="icon_more_vertical"
                  [current]="true"
                ></app-svg-icon>
                <ng-template #popDelete class="whiteDropdown">
                  <span class="icon">
                    <app-svg-icon
                      icon="trash_document-icon"
                      [current]="true"
                      class="svg__icon"
                    >
                    </app-svg-icon>
                    <button
                      class="btn-text-green"
                      style="padding-left: 0"
                      (click)="deleteActivity(activity.children[0].id)"
                    >
                      {{ "commons.delete" | translate }}
                    </button>
                  </span>
                </ng-template>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </nz-table>
</section>
