<section class="app-env-licensing-opinion sl__simpleLayout">
  <div class="sl__simpleLayout__card">
    <header class="sl__simpleLayout__card__header">
      <h2 class="sl__simpleLayout__card__header__title">
        {{ getTitle() | translate }}
      </h2>
    </header>
    <ng-container *ngIf="isRoleGestor">
      <app-select-multiple-tag
        [id]="'select-multiple-tag-actions'"
        [name]="getActionsLabel()"
        errorMessage=""
        [maxTagCount]="3"
        [status]="checkInputForm('actions')"
        [listOfOption]="actionsList"
        [controls]="form.get('actions')"
        (changeValue)="onHandleFormChanges()">
      </app-select-multiple-tag>

      <app-select-multiple-tag
        [id]="'select-multiple-tag-impacts'"
        [name]="getImpactsLabel()"
        errorMessage=""
        [maxTagCount]="3"
        [status]="checkInputForm('impacts')"
        [listOfOption]="impactsList"
        [controls]="form.get('impacts')"
        (changeValue)="onHandleFormChanges()"
        [required]="false">
      </app-select-multiple-tag>

      <app-input-textarea
        [id]="'input-text-area-detail'"
        [name]="'Observações sobre as medidas necessárias e impactos'"
        errorMessage=""
        [status]="' '"
        [styleResize]="'none'"
        [required]="false"
        [controls]="form.get('comments')"
        (changeValue)="onHandleFormChanges()">
      </app-input-textarea>
    </ng-container>

    <app-badge-chips
      badgeColor="warning"
      [badgeText]="getWarning()"
      badgeIcon="icon_warning_neutral"
      *ngIf="!isRoleGestor"
    ></app-badge-chips>

  </div>
</section>
