import { Component, type OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivePageService } from 'src/app/shared/common/services/active-page/active-page.service';
import { TopBreadcrumbService } from 'src/app/shared/common/services/top-breadcrumb/top-breadcrumb.service';
import { RoutesEnum } from 'src/app/shared/enum/routes.enum';
import { setPages } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import { BreadcrumbState } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';
import { Page } from 'src/app/shared/ngrx/breadcrumb/pages.model';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
  constructor(
    private service: UserService,
    private activePage:ActivePageService,
    private store: Store<BreadcrumbState>,
  ) {}

  flagTopMenu = false;

  ngOnInit(): void {
    this.dispatchProjectOnStore();
  }


  private dispatchProjectOnStore(): void {

    this.activePage.setPage('documents');

    this.service.flagTopMenu$.subscribe((flag) => {
      this.flagTopMenu = flag;
    });

    const pagesArray: Page[] = [
      {
        name: "Documentos",
        url: `/${RoutesEnum.DOCUMENTS}`
      }
    ];

    this.store.dispatch(setPages({pages: pagesArray}));
  }
}
