import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {NzModalService} from "ng-zorro-antd/modal";
import {VideoModalComponent} from "./video-modal/video-modal.component";
import { RoutesEnum } from 'src/app/shared/enum/routes.enum';
import { setPages } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import { Page } from 'src/app/shared/ngrx/breadcrumb/pages.model';
import { BreadcrumbState } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss']
})
export class HelpCenterComponent implements OnInit {

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private store: Store<BreadcrumbState>,
  ) {}

  ngOnInit(): void {
    this.dispatchProjectOnStore();
  }

  private dispatchProjectOnStore(): void {

    const pagesArray: Page[] = [
      {
        name: "Central de Ajuda",
        url: `/${RoutesEnum.HELP_CENTER}`
      }
    ];

    this.store.dispatch(setPages({pages: pagesArray}));
  }

  showVideo(title: string, url: string): void {
    const modal = this.modal.create<VideoModalComponent>({
      nzTitle: title,
      nzWidth: 780,
      nzContent: VideoModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
      nzOnCancel: () => {
        modal.destroy();
      },
    });
    const instance = modal.getContentComponent();
    instance.url = url;
  }

}
