<div
  nz-col
  nzXs="24"
  nzSm="24"
  nzMd="24"
  nzLg="24"
  nzXl="24"
  class="app-project-suspension">
  <section class="sl__simpleLayout">
    <header>
      <div class="sl__simpleLayout__header__content">
        <button
          type="button"
          id="button-back"
          class="sl__simpleLayout__header__buttonBack"
          (click)="goBack()">
          <img src="assets/icons/icon_arrow-back.svg" alt="Voltar"/>
        </button>
        <h1 *ngIf="suspension">
          {{ ("projects.suspension.title" | translate) + suspension.name }}
        </h1>
      </div>

      <div class="sl__simpleLayout__header__nav">
        <app-button-icon-primary
          [id]="'btn-icon'"
          [icon]="'icon_check_small'"
          [text]="'Enviar parecer'"
          altText="{{ 'Ícone Check' }}"
          (click)="redirectToFinalAnalysis()"
          *ngIf="suspension && suspension.confirmationSendDate === null && publishFinalAnalysisSuspension">
        </app-button-icon-primary>
      </div>
    </header>
  </section>

  <div class="outer__wrapper">
    <div class="outer__wrapper_content" nz-col nzSpan="9">
      <div class="round-border suspension-infos-container">
        <app-resume-scope-change-request-container
          [projectSummary]="projectSummary"
          [suspension]="suspension"
          [suspensionLabel]="label"
        >
        </app-resume-scope-change-request-container>

        <app-generic-non-editable-table
          *ngIf="licensingProcessList.length > 0"
          [dataSource]="licensingProcessList"
          [tableTitle]="'Licenciamentos'"
        >
        </app-generic-non-editable-table>

        <app-generic-non-editable-table
          *ngIf="studiesList.length > 0"
          [dataSource]="studiesList"
          [tableTitle]="'Estudos'"
        >
        </app-generic-non-editable-table>
      </div>
    </div>

    <div nz-col [nzSpan]="15" class="second_column">
      <div class="mb-16">
        <app-analysis-about
          [edit]="editSuspensionUserResponsible"
          [analysisInformations]="analysisInfo"
          [getUserList]="getUserListForAnalysis"
          (changeValue)="getValueChanged($event)">
        </app-analysis-about>
      </div>
      <app-env-licensing-opinion
        class="mt-16"
        *ngIf="suspension && suspension.confirmationSendDate === null"
        [suspension]="suspension"
        (handleFormChanges)="handleDetailAboutRequest($event)">
      </app-env-licensing-opinion>

      <app-env-licensing-opinion-summary
        class="mt-16"
        *ngIf="suspension && suspension.confirmationSendDate !== null"
        [suspension]="suspension">
      </app-env-licensing-opinion-summary>

      <app-request-analysis-status-me
        *ngIf="suspension"
        [confirmationUserName]="confirmationUserName"
        [confirmationSuspensionId]="suspensionId"
        [projectId]="projectId"
        [isSuspension]="suspension.type === 'SUSPENSION'"
        [finalComments]="finalComments"
        [confirmationDate]="confirmationDate"
        [confirmationSendDate]="confirmationSendDate"
        [suspensionStatus]="suspensionStatus"
        [deadline]="deadline">
      </app-request-analysis-status-me>
    </div>
  </div>
</div>
