import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {NOTIFICATIONS_API} from '../projects/constants/apiEndpointsConstants';
import { HttpClient } from '@angular/common/http';
import {NotificationsModel} from 'src/app/shared/models/notifications.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private http: HttpClient) {
  }

  list = {
    id: 0,
    type: 'plano de alguma coisa',
    entityName: 'Projetinho Legal',
    dateTimeNotification: '10/10/2020',
    sentTo: 'Luiz Lopes',
    entityId: 2,
    status: 'Ok',
    notificationRecipients: [],
    read: false
  };

  getAllNotifications(size = 500): Observable<any> {
    return this.http.get<NotificationsModel>(
      `${NOTIFICATIONS_API}?size=${size}&topDays=100`
    );
  }


}
