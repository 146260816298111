<section class="app-request-analysis-status-me sl__simpleLayout">
  <!-- Bloco Suspensão Confirmada -->
  <div *ngIf="confirmationDate && confirmationSendDate" class="sl__simpleLayout__card">
    <header class="sl__simpleLayout__card__header">
      <h2 class="sl__simpleLayout__card__header__title">
        <!-- Confirmação da área solicitante -->
        {{ "projects.scope-change.confirmation-title" | translate }}
      </h2>
    </header>
    <div>
      <div class="summary__data">
        <div class="summary__data__item">
          <p class="summary__data__item__title">
            <!-- Suspensão confirmada? -->
            {{ "projects.scope-change.suspension-confirmed" | translate }}
          </p>
          <p class="summary__data__item__description">Sim</p>
        </div>

        <div class="summary__data__item">
          <p class="summary__data__item__title">
            {{ "projects.scope-change.confirm-user" | translate }}
          </p>
          <p class="summary__data__item__description">
            {{ confirmationUserName }}
          </p>
        </div>

        <div class="summary__data__item">
          <p class="summary__data__item__title">
            <!-- Data da confirmação -->
            {{ "projects.scope-change.confirmation-date" | translate }}
          </p>
          <p class="summary__data__item__description">
            {{ confirmationDate | date : "dd/MM/yyyy" }}
          </p>
        </div>

        <div class="summary__data__item">
          <p class="summary__data__item__title">
            <!-- Comentários finais -->
            {{ "projects.scope-change.final-comments" | translate }}
          </p>
          <p class="summary__data__item__description">
            {{ finalComments }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- Bloco Confirmar Suspensão -->
  <div *ngIf="suspensionStatus.confirmationSendDate &&
              suspensionStatus.status === 'EAN' &&
              (isUserDemandant || isUserGestor) &&
              suspensionResponse"
              class="sl__simpleLayout__card">
    <header class="sl__simpleLayout__card__header">
      <h2 class="sl__simpleLayout__card__header__title">
        <!-- Confirmação da área solicitante -->
        {{ "projects.scope-change.confirmation-title" | translate }}
      </h2>
      <p class="summary__data__item__subtitle mt-5">
        Com base no parecer de licenciamento ambiental, você confirma a
        realização do processo de {{ this.isSuspension ? 'suspensão' : 'cancelamento'}} ?
      </p>
    </header>
    <div>
      <div class="summary__data__item return-deadline" *ngIf="suspensionStatus.confirmationSendDate">
        <p class="summary__data__item__title">
          <!-- Data da confirmação -->
          Prazo para retorno
        </p>
        <p class="summary__data__item__description {{deadLineColor}}">
          {{ this.deadLineDate | date : "dd/MM/yyyy" }}
        </p>
      </div>
      <app-input-textarea
        [id]="'input-textarea'"
        (changeValue)="setConfigMessage($event)"
        [required]="true"
        [name]="'Comentários finais'">
      </app-input-textarea>

    </div>
    <div class="sl__simpleLayout__card__footer">
      <app-button-icon-secondary
        text="{{this.textButtonsCancel}}"
        (click) = "openCancelationModal()"
      ></app-button-icon-secondary>
      <app-button-icon-primary
        text="{{this.textButtonsConfirm}}"
        [icon]="'icon_check_small'"
        (click)="openConfirmationModal()"
      ></app-button-icon-primary>
    </div>
  </div>

  <!-- Bloco Suspensão Não respondida pelo Gestor-->
  <div *ngIf="!(isUserGestor &&
                isUserLicensing &&
                isUserEspecialist &&
                isUserLicensing &&
                isUserDemandant) ">
    <div *ngIf="(isUserGestor || isUserLicensing || isUserEspecialist || isUserLicensing) &&
                suspensionStatus.confirmationDate === null &&
                suspensionStatus.finalComments === null">
      <div class="sl__simpleLayout__card">
        <header class="sl__simpleLayout__card__header">
          <h2 class="sl__simpleLayout__card__header__title">
            <!-- Confirmação da área solicitante -->
            {{ "projects.scope-change.confirmation-title" | translate }}

          </h2>
        </header>
        <div>
          <div class="summary__data__item return-deadline" *ngIf="suspensionStatus.confirmationSendDate">
            <p class="summary__data__item__title">
              <!-- Data da confirmação -->
              Prazo para retorno
            </p>
            <p class="summary__data__item__description {{deadLineColor}}">
              {{ this.deadLineDate | date : "dd/MM/yyyy" }}
            </p>
          </div>
            <app-badge-chips
              badgeColor="{{badgeColor}}"
              badgeText="projects.scope-change.pending-status"
              badgeIcon="{{badgeIcon}}"
            >
            </app-badge-chips>
        </div>
      </div>
    </div>
  </div>

  <!-- Bloco Suspensão Não respondida-->
  <div *ngIf="!isUserDemandant && !isUserGestor && !isUserLicensing && !isUserEspecialist && suspensionView">
    <div class="sl__simpleLayout__card">
      <header class="sl__simpleLayout__card__header">
        <h2 class="sl__simpleLayout__card__header__title">
          <!-- Confirmação da área solicitante -->
          {{ "projects.scope-change.confirmation-title" | translate }}
        </h2>
      </header>
      <div>
        <div class="summary__data">
          <div class="summary__data__item">
            <p class="summary__data__item__title">
              <!-- Prazo para retorno -->
              {{ "projects.scope-change.deadline-answer" | translate }}
            </p>
            <p class="summary__data__item__description">
              {{ deadline | date : "dd/MM/yyyy" }}
              <span>
                <img
                  src="../../../../../../assets/icons/icon_information.svg"
                  alt="Informação"
                  nz-tooltip
                  nzTooltipPlacement="bottom"
                  nzTooltipTitle="A área solicitante ainda não respondeu à confirmação da suspensão do projeto"
                />
              </span>
            </p>
          </div>
          <app-badge-chips
            badgeColor="warning"
            badgeText="projects.scope-change.pending-status"
            badgeIcon="icon_warning_neutral"
          >
          </app-badge-chips>
        </div>
      </div>
    </div>
  </div>

  <!-- Bloco Suspensão Cancelada-->
  <div *ngIf="false">
    <div class="sl__simpleLayout__card">
      <header class="sl__simpleLayout__card__header">
        <h2 class="sl__simpleLayout__card__header__title">
          <!-- Confirmação da área solicitante -->
          {{ "projects.scope-change.confirmation-title" | translate }}
        </h2>
      </header>
      <div>
        <div class="summary__data">
          <div class="summary__data__item">
            <p class="summary__data__item__title">
              <!-- Prazo para retorno -->
              {{ "projects.scope-change.deadline-answer" | translate }}
            </p>
            <p class="summary__data__item__description">
              {{ deadline | date : "dd/MM/yyyy" }}
              <span>
                <img
                  src="../../../../../../assets/icons/icon_information.svg"
                  alt="Informação"
                  nz-tooltip
                  nzTooltipPlacement="bottom"
                  nzTooltipTitle="Requisição cancelada por falta de confirmação da mudança dentro do prazo determinado."
                />
              </span>
            </p>
          </div>
          <app-badge-chips
            badgeColor="warning"
            badgeText="projects.scope-change.pending-status"
            badgeIcon="icon_warning_neutral"
          >
          </app-badge-chips>
        </div>
      </div>
    </div>
  </div>

</section>
