<div class="app-button-options">
    <button
        class="btn__primary btn__primary__green"
        nz-popover
        [nzPopoverPlacement]="popoverPlacement"
        nzPopoverTrigger="click"
        [nzPopoverContent]="contentTemplate">

        <span class="btn__primary__span"> {{ title | translate }} </span>

        <div class="btn__primary__line"></div>

        <app-svg-icon-custom
            width="12" height="12"
            viewBox="0 0 12 12"
            [icon]="'icon_arrow_down_small'">
        </app-svg-icon-custom>

    </button>

    <ng-template #contentTemplate>
        <div class="botoesMenu" *ngFor="let menu of menuConfig" [style]="styleCss">
            <span (click)="onClickActionMenu(menu.eventType)" class="span__btn">
                <img *ngIf="menu.icon !== ''" class="mr-5" 
                    [src]="getIconPath(menu.icon)" [alt]="menu.title"> 
                    {{ menu.title | translate }}
            </span>
        </div>
    </ng-template>
</div>
