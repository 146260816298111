import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// COMPONENTS
import { ButtonSendComponent } from './button-send/button-send.component';
import { ButtonCancelComponent } from './button-cancel/button-cancel.component';
import { ButtonSaveDraftComponent } from './button-save-draft/button-save-draft.component';
import { ButtonIconPrimaryComponent } from './button-icon-primary/button-icon-primary.component';
import { ButtonIconSecondaryComponent } from './button-icon-secondary/button-icon-secondary.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/app.module';
import { HttpClient } from '@angular/common/http';
import { ButtonOptionsComponent } from './button-options/button-options.component';
import { IconsLicensingModule } from '../icons/icons-licensing.module';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

const COMPONENTS = [
  ButtonSendComponent,
  ButtonCancelComponent,
  ButtonSaveDraftComponent,
  ButtonIconPrimaryComponent,
  ButtonIconSecondaryComponent,
  ButtonOptionsComponent
]

const MODULES = [
  NzPopoverModule,
  IconsLicensingModule
]

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [
    CommonModule,
    [...MODULES],
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ]
})
export class ButtonsLicensingModule { }
