import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-icon-primary',
  templateUrl: './button-icon-primary.component.html',
  styleUrls: ['./button-icon-primary.component.scss']
})
export class ButtonIconPrimaryComponent {
  @Input() id?: string = '';
  @Input() icon: string = '';
  @Input() text: string = '';
  @Input() width?: string = '';
  @Input() fontSize?: string = '12px';
  @Input() altText?: string = '';
  @Input() customClass?: string = '';
  @Input() customClassBtn?: string = '';
  @Input() disabledBtn?: boolean = false;
  iconPath: string = '';
  constructor() { }

  getIconPath(iconName: string): string {
    return `./assets/icons/${iconName}.svg`;
  }
}
