import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
@Component({
  selector: 'app-dashboard-licensing',
  templateUrl: './dashboard-licensing.component.html',
  styleUrls: ['./dashboard-licensing.component.scss'],
})
export class DashboardLicensingComponent implements AfterViewInit {

  @ViewChild('powerBiFrame') powerBiFrame: ElementRef<HTMLIFrameElement>;

  constructor(
    private renderer: Renderer2
  ) {}

  ngAfterViewInit() {
    const iframeElement = this.powerBiFrame.nativeElement;

    iframeElement.onload = () => {
      const iframeDocument = iframeElement.contentDocument || iframeElement.contentWindow?.document;

      if (iframeDocument) {
        const statusBarElement = iframeDocument.querySelector('.statusBar');
        alert(statusBarElement)
        if (statusBarElement) {
          this.renderer.addClass(statusBarElement, 'esconder-item');
        }
      }
    };
  }

}
