<div class="app-select-search sl__form">
    <div class="sl__form__label">
        <span class="sl__form__required fontSize12" *ngIf="required">*</span>
        <span class="sl__form__title fontSize12">{{ optionLabel }}</span>
    </div>

    <p-dropdown 
        class="fontSize12"
        [options]="listOfOption"
        [(ngModel)]="selectedItem"
        [disabled]="disabled"
        optionLabel="description"
        [filter]="true"
        filterBy="description,code"
        [showClear]="showClear"
        placeholder="Selecione"
        (ngModelChange)="selectItem($event)">
        <ng-template pTemplate="selectedItem" let-selectedOption>
            <div class="flex align-items-center gap-2">
                <div class="fontSize12" *ngIf="selectedOption.code">{{ selectedOption.code }} - {{ selectedOption.description }}</div>
                <div class="fontSize12" *ngIf="!selectedOption.code">{{ selectedOption.description }}</div>
            </div>
        </ng-template>
        <ng-template let-item pTemplate="item">
            <div class="flex align-items-center gap-2">
                <div class="fontSize12" *ngIf="item.code">{{ item.code }} - {{ item.description }}</div>
                <div class="fontSize12" *ngIf="!item.code">{{ item.description }}</div>
            </div>
        </ng-template>
    </p-dropdown>
</div>
