<section class="generic-non-editable-table-container">
  <h2 class="sl__simpleLayout__card__title">
    {{ tableTitle | translate}}
  </h2>

  <nz-table nzTemplateMode nzSize="small" #basicTable [nzData]="displayData" [nzFrontPagination]="false">
    <thead>
      <tr>
        <th class="tableTh" *ngFor="let column of columns">
          {{ column }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data; let i = index" [ngClass]="links[i] ? 'clickable' : ''" (click)="redirectToLink(i)">
        <td class="tableTd" *ngFor="let column of columns" [ngClass]="data[column][0] === 'status' ? 'status_td' : ''">
          <span *ngIf="data[column][0] === 'text'" class="scopeChangeValue">{{ data[column][1] }}</span>
          <app-badge
            class="status_badge"
            *ngIf="data[column][0] === 'status'" [badgeText]="data[column][1]"
          ></app-badge>
        </td>
      </tr>
    </tbody>
  </nz-table>
</section>
