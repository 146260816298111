<div>
  <section *ngFor="let alert of alerts" [class]="alert.type + '_alert alert_card'">
    <div class="card_infos">
      <img *ngIf="alert.type === 'error'" class="alert__icon" src="/assets/icons/icon_warning_red.svg" alt="" />
      <img *ngIf="alert.type === 'success'" class="alert__icon" src="/assets/icons/icon_success_green.svg" alt="" />
      <span>{{ alert.message }}</span>
    </div>

    <button (click)="removeAlert(alert.id)" class="close_alert__btn">
      <img class="close__icon" src="/assets/icons/icon_close_green.svg" alt="Close icon" />
    </button>
  </section>
</div>