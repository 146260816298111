<app-menu-dashboards (selectedTypeDisplay)="handleCheckClick($event)" [currentPage]="currentPage"></app-menu-dashboards>

<div nz-row class="dashboard">

  <!-- DASHBOARD TIPO LICENSING -->
  <div
    *ngIf="dashboardTypeLicensing"
    nz-col
    nzXs="24"
    nzSm="24"
    nzMd="24"
    nzLg="18"
    nzXl="24"
  >
    <app-dashboard-licensing></app-dashboard-licensing>
  </div>

  <!-- DASHBOARD TIPO ESTUDO AMBIENTAL -->
  <div
    *ngIf="dashboardTypeEia"
    nz-col
    nzXs="24"
    nzSm="24"
    nzMd="24"
    nzLg="18"
    nzXl="24"
  >
    <app-dashboard-estudos-ambientais></app-dashboard-estudos-ambientais>
  </div>

  <!-- DASHBOARD TIPO GERENCIAL -->
  <div
    *ngIf="dashboardTypeManager"
    nz-col
    nzXs="24"
    nzSm="24"
    nzMd="24"
    nzLg="18"
    nzXl="24"
  >
   <app-dashboard-gerencial></app-dashboard-gerencial>
  </div>

  <!-- DASHBOARD TIPO FORNECEDORES -->
  <div
    *ngIf="dashboardTypeSupplier"
    nz-col
    nzXs="24"
    nzSm="24"
    nzMd="24"
    nzLg="18"
    nzXl="24"
  >
   <app-dashboard-fornecedores></app-dashboard-fornecedores>
  </div>
</div>
