import {ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewContainerRef} from '@angular/core';
import {NlpResultResponseDto} from "./dto/nlp-result-response.dto";
import {ResultSectionNlpTrDto} from "./dto/result-section-nlp-tr.dto";
import {NlpModalEvaluateSectionComponent} from '../nlp-modal-evaluate-section/nlp-modal-evaluate-section.component';
import {NzModalService} from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-result-body',
  templateUrl: './result-body.component.html',
  styleUrls: ['./result-body.component.scss']
})
export class ResultBodyComponent  {
  @Input() resultAnalysisNlp?: NlpResultResponseDto;
  @Input() resultAnalysisNlpOriginal?: NlpResultResponseDto;
  @Output() public updateEvaluation: EventEmitter<void> = new EventEmitter<void>();
  filterText: string = '';
  filterSection: string = '';
  filterAderencia: string = '';

  modalClosedSuccessfully = false;

  constructor(private modalService: NzModalService,
              private readonly viewContainerRef: ViewContainerRef,
              private cdr: ChangeDetectorRef) {
  }

  getActiveSection(section: ResultSectionNlpTrDto): boolean {
    if (!section.documentSection) {
      return false;
    }
    return section.documentSection.dsOrder === 1;
  }

  evaluateSection(sectionId: number, evaluation: boolean): void {
    const titulo = evaluation ? "Aprovação da seção do estudo" : "Reprovação da seção do estudo";

    const modal = this.modalService.create<NlpModalEvaluateSectionComponent>({
      nzTitle: titulo,
      nzWidth: 780,
      nzContent: NlpModalEvaluateSectionComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
      nzOkLoading: true,
      nzCancelText: 'No',
      nzOnCancel: () => {
        this.modalClosedSuccessfully = true
      }
    });
  // ,
  //   nzComponentParams: {
  //     sectionId: sectionId,
  //       evaluation: evaluation
  //   }

    const instance = modal.getContentComponent();
    instance.sectionId = sectionId;
    instance.evaluation =evaluation;

    modal.afterClose.subscribe(() => {
      this.updateEvaluation.emit();
      if (this.modalClosedSuccessfully) {
        this.cdr.detectChanges();
      } else {
        this.cdr.detectChanges();
      }
      this.modalClosedSuccessfully = false;
    });
  }

  searchFilter(): void {
    this.resultAnalysisNlp = JSON.parse(JSON.stringify(this.resultAnalysisNlpOriginal));
    if (this.filterAderencia === '' && this.filterSection === '' && this.filterText === '') {
      this.resultAnalysisNlp = this.resultAnalysisNlpOriginal;
      return;
    }

    if (!this.resultAnalysisNlp) {
      return;
    }

    if (this.resultAnalysisNlp?.resultSectionNlpAndTrResponseList !== undefined) {
      this.resultAnalysisNlp.resultSectionNlpAndTrResponseList = this.resultAnalysisNlp.resultSectionNlpAndTrResponseList
        .filter((element) =>
          (element.resultSections?.statusColor.toLowerCase().includes(this.filterAderencia.toLowerCase())) &&
          (element.documentSection.title.toLowerCase().includes(this.filterSection.toLowerCase())) &&
          (element.documentSection.description.toLowerCase().includes(this.filterText.toLowerCase()) ||
            element.resultSections.sectionDescription.toLowerCase().includes(this.filterText.toLowerCase()))
        );
    }

  }



}
