import {Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {BehaviorSubject, Observable, of, tap} from "rxjs";
import {
  ATTACHMENT_MANDADORY_DOC,
  COMPLEX_API,
  COMPLEX_REGION_API, DISCIPLINE_ASSESSMENT_BASE_API,
  DISCIPLINE_ASSESSMENT_LIST,
  MANDATORY_DOCUMENTS,
  OPERATIONAL_UNITS,
  PROCESS_BY_PROJECT_ID,
  PROJECT_API,
  PROJECT_SUSPENSION_API,
  PROJECTS_V2,
  PROJECTS_V2_SEND_ANALISYS,
  REGION_API,
  REQUESTING_AREA,
  SCOPE_CHANGE_BY_ID,
  SUB_PROJECT_REVIEWS,
  UPDATE_SUB_PROJECT_REVIEWS
} from "../../modules/projects/constants/apiEndpointsConstants";
import {SubProjectModelReq} from "../models/projects-v2.model";
import {RequestingArea} from '../models/requestingArea.model';
import {RegionModel} from '../models/region.model';
import {ComplexModel} from '../models/complex.model';
import {OperacionalUnitModel} from '../models/operacionalUnit.model';
import {MandatoryDocumentsModel} from '../models/mandatory-documents.model';
import {ProjectV2ViewResponseDto} from "../dto/project-v2-view-response.dto";
import {SubProjectPageResponse} from "../dto/suprojects-page-response.dto";
import {ProjectByIdResponseDto} from "../dto/project-by-id-response.dto";
import { PageableProcessListResponseDto } from '../dto/process-list-response.dto';
import {SubProjectByIdResponse} from "../dto/subproject-by-id-response.dto";
import { DisciplineBySubProjectListResponse } from '../dto/discipline-by-subproject-list-response.dto';
import { ProjectSuspension } from '../dto/project-suspension.dto';
import {EnvLicensingOpinionRequestDto} from "../dto/env-licensing-opinion-request.dto";

@Injectable({
  providedIn: 'root'
})
export class ProjectsV2Service {

  constructor(private readonly http: HttpClient) {
  }

  private project: any;
  private projectName: any;
  private projectToCreateRipla: any;
  private scopechange: any;

  getProjects(pageSize: number, filters?: any): Observable<any> {
    let url = `${PROJECTS_V2}/page?size=${pageSize}`;

    if(filters){
      for (const [key, value] of Object.entries(filters)) {
        if (value) {
          url += `&${key}=${value}`;
        }
      }
    }
    return this.http.get<any>(url);
  }

  setProjectToEdit(project){
    this.project = project;
  }

  setProjectToCreateRipla(project) {
    this.projectToCreateRipla = project;
  }

  getProject(){
    return this.project;
  }

  projectCache(id){
    if(this.project && id ===  this.project.id){
      return of(this.project);
    } else {
      return this.http.get<ProjectByIdResponseDto>(`${PROJECT_API}/${id}`).pipe(
        tap(data => this.scopechange = data)
      );
    }
  }

  getProjectToCreateRipla(){
    if(this.projectToCreateRipla){
      return {
        project: this.projectToCreateRipla,
        createRipla: true
      }
    }
    return this.projectToCreateRipla;
  }

  getAreas(): Observable<RequestingArea> {
    const url = `${REQUESTING_AREA}`;
    return this.http.get<RequestingArea>(url);
  }

  createArea(body: any): Observable<RequestingArea> {
    const url = `${REQUESTING_AREA}`;
    return this.http.post<RequestingArea>(url, body);
  }

  getRegions(): Observable<RegionModel> {
    const url = `${REGION_API}`;
    return this.http.get<RegionModel>(url);
  }

  getComplex(): Observable<ComplexModel> {
    const url = `${COMPLEX_API}`;
    return this.http.get<ComplexModel>(url);
  }

  getComplexById(idRegion: number): Observable<ComplexModel> {
    const url = `${COMPLEX_REGION_API}/${idRegion}`;
    return this.http.get<ComplexModel>(url);
  }

  getOperationalUnits(idComplex: number): Observable<OperacionalUnitModel> {
    const url = `${OPERATIONAL_UNITS}/${idComplex}`;
    return this.http.get<OperacionalUnitModel>(url);
  }

  getMandatoryDocuments(params: any): Observable<[MandatoryDocumentsModel]> {
    const url = `${MANDATORY_DOCUMENTS}/${params.PARAM_PROJ_TYPE}/${params.PARAM_CLASSIFICATION}`;
    return this.http.get<[MandatoryDocumentsModel]>(url);
  }


  saveRipla(body: any){
    return this.http.post<any>(`${PROJECTS_V2}`, body)
  }

  saveRiplaToAnalisys(id: any){
    return this.http.patch<any>(`${PROJECTS_V2_SEND_ANALISYS}/${id}`, null)
  }

  updateSubProjectRascunho(body: any, id: number) {
    return this.http.put<SubProjectModelReq>(`${PROJECTS_V2}/${id}`, body)
  }

  getProjectById(projectId: number): Observable<ProjectV2ViewResponseDto> {
    return this.http.get<ProjectV2ViewResponseDto>(`${PROJECTS_V2}/${projectId}`);
  }

  getSubProjectsByProjectId(projectId: number): Observable<SubProjectPageResponse> {
    return this.http.get<SubProjectPageResponse>(`${PROJECTS_V2}/page?projectId=${projectId}`);
  }

  editSubProjectRascunho(body: any, id: number){
    return this.http.patch<SubProjectModelReq>(`${UPDATE_SUB_PROJECT_REVIEWS}/${id}`, body)
  }

  getMandatoryDocsAttachments(id, ENTITY_NAME: string) {
    return this.http.get<any>(`${ATTACHMENT_MANDADORY_DOC}?entityId=${id}&entityName=${ENTITY_NAME}`)
  }

  getProjectByProjectId(projectId: number): Observable<ProjectByIdResponseDto> {
    return this.http.get<ProjectByIdResponseDto>(`${PROJECT_API}/${projectId}`);
  }

  getSubProjectBySubProjectId(subProjectId: number): Observable<any> {
    return this.http.get<any>(`${PROJECTS_V2}/${subProjectId}`);
  }

  updateSubProjectUser(subProjectId: number, body: any): Observable<SubProjectByIdResponse> {
    return this.http.patch<SubProjectByIdResponse>(`${PROJECTS_V2}/user-responsible/${subProjectId}`,body);
  }

  updateSubProjectUserByDiscipline(subProjectId: number, body: any): Observable<SubProjectByIdResponse> {
    return this.http.patch<SubProjectByIdResponse>(`${PROJECTS_V2}/user-responsible/${subProjectId}`,body);
  }

  updateSubProjectColaborativeDocument(subProjectId: number, document: string): Observable<SubProjectByIdResponse> {
    const requestBody = {
      "url": document
    }
    return this.http.patch<SubProjectByIdResponse>(`${PROJECTS_V2}/${subProjectId}/analysis-document`, requestBody);
  }

  getProcessessList(projectId: number): Observable<PageableProcessListResponseDto>{

    return this.http.get<any>(`${PROCESS_BY_PROJECT_ID}/${projectId}`);
  }

  getPageableProcessesList(projectId: number, filters: any): Observable<PageableProcessListResponseDto>{
    let url = `${PROCESS_BY_PROJECT_ID}/${projectId}?size=200`;

    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
        if (value) {
          url += `&${key}=${value}`;
        }
      }
    }

    return this.http.get<any>(url);
  }

  getDisciplineAssessmentList(projectId: number, subprojectId: number): Observable<DisciplineBySubProjectListResponse[]> {
    return this.http.get<DisciplineBySubProjectListResponse[]>(`${DISCIPLINE_ASSESSMENT_LIST}/${subprojectId}`)
  }

  addNewDisciplineSubProject(request: any, subprojectId: number):Observable<any>{
    return this.http.post(`${PROJECTS_V2}/${subprojectId}/disciplines`, request);
  }

  addNewDisciplineToScopeChange(request: any):Observable<any>{
    return this.http.post(`${DISCIPLINE_ASSESSMENT_BASE_API}/for-scope-change`, request);
  }

  setScopeChange(data){
    this.scopechange = data;
  }

  //Desfeito alteração, pois impactava nos resolvers,
  // a alteração implica em testar todos os resolver que utilizam o serviço
  getScopeChange(id): Observable<any>{
    return this.http.get<any[]>(`${SCOPE_CHANGE_BY_ID}/${id}`);
  }

  getScopeChangeById(id:number):Observable<any>{
    return this.http.get<DisciplineBySubProjectListResponse[]>(`${SCOPE_CHANGE_BY_ID}/${id}`)
  }

  deleteDisciplineBySubProjectId(subprojectId: number, assessmentIdToDelete: number): Observable<any> {
    return this.http.delete<any>(`${DISCIPLINE_ASSESSMENT_LIST}/${subprojectId}/${assessmentIdToDelete}`)
  }

  deleteDisciplineById(disciplineId: number): Observable<any> {
    return this.http.delete<any>(`${DISCIPLINE_ASSESSMENT_BASE_API}/${disciplineId}`)
  }

  getSubprojectReview(revisionId: number, subProjectId: number): Observable<any> {
    return this.http.get<any>(`${SUB_PROJECT_REVIEWS}/${revisionId}/${subProjectId}`);
  }

  getProjectsByUserComplex(): Observable<any> {
    return this.http.get<any>(`${PROJECT_API}/byUserComplex`);
  }

  getProjectSuspension(id: number): Observable<ProjectSuspension> {
    return this.http.get<ProjectSuspension>(`${PROJECT_SUSPENSION_API}/${id}`);
  }

  updateSuspensionResponsible(suspensionId: number, userId: number): Observable<ProjectSuspension> {
    return this.http.patch<ProjectSuspension>(`${PROJECT_SUSPENSION_API}/responsible-user`, {
      "suspensionId": suspensionId,
      "userId": userId
    })
  }

  sendEnvLicensingOpinion(request: EnvLicensingOpinionRequestDto): Observable<any> {
    return this.http.patch<any>(`${PROJECT_SUSPENSION_API}/final-analysis`,request);
  }

  getProjectsWithoutActiveRequests(): Observable<any>{
    return this.http.get<any>(`${PROJECT_API}/withoutActiveRequests`);
  }

}
