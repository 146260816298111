import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard-fornecedores',
  templateUrl: './dashboard-fornecedores.component.html',
  styleUrls: ['./dashboard-fornecedores.component.scss'],
})
export class DashboardFornecedoresComponent{

  constructor() {}

}
