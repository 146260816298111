import { Component, Input } from '@angular/core';
import { EventEmitter, Output } from '@angular/core';
import moment from 'moment/moment';
import { differenceInCalendarDays } from 'date-fns';
import { UserModel } from 'src/app/shared/models/user.model';
import { TextShortnerHelper } from 'src/app/shared/components/text-shortner/text-shortner.helper';
import { SummaryAboutProject } from '../../../interface/summary-scope-change';
import { ScopeChangeRequest, ScopeChangeResponse } from '../../../interface/scope-change-request';
import { MessageService } from 'src/app/shared/common/services/message.service';
import { ProjectByIdResponseDto } from 'src/app/shared/dto/project-by-id-response.dto';
import { SubProjectByIdResponse } from 'src/app/shared/dto/subproject-by-id-response.dto';
import { User } from 'src/app/shared/dto/licenses-response.dto';
import { Observable } from 'rxjs';
import { AnalysisInformations } from 'src/app/shared/dto/analysis-info.dto';
import {ScopeChangeService} from "../../../service/scope-change/scope-change.service";

@Component({
  selector: 'app-analysis-about',
  templateUrl: './analysis-about.component.html',
  styleUrls: ['./analysis-about.component.scss'],
})
export class AnalysisAboutComponent {
  @Input() scopeChangeId: number;
  @Input() analysisInformations: AnalysisInformations | null;
  @Input() scopeChange!: ScopeChangeResponse | null;
  @Input() project!: ProjectByIdResponseDto | null;
  @Input() edit?: boolean = true;
  @Input() isClosedOrCancelled: boolean;
  @Input() getUserList: () => Observable<UserModel[]> | null;
  @Input() setUserSelected: (user: User) => void;

  @Output() changeValue: EventEmitter<number> = new EventEmitter<number>();

  editingRow: boolean = false;
  projectId: number;
  today = new Date();
  isEditing: boolean;
  subProjectId: number;
  users: UserModel[] = [];
  loadingSubProjects: boolean;
  editId: string | null = null;
  newIdDiscipline?: string = '';
  newStatusDiscipline: string = '';
  private disciplineInEditMode: any;
  addNewDiscipline: boolean = false;
  usersSpecialist: UserModel[] = [];
  subProject: SubProjectByIdResponse;
  editIdDiscipline: string | null = null;
  editDateDeadlineDiscipline: string = '';
  newDateDeadlineDiscipline?: string = '';
  newUserResponsibleIdDiscipline?: string = '';
  selectedUserResponsibleIdDiscipline: number = 0;
  projectSummary: SummaryAboutProject | undefined = undefined;
  customStyle = {
    background: 'white',
    'font-size': '12px',
  };
  popOverEditRipla: boolean = false;
  popOverEditDiscipline: boolean = false;

  compareFn = (o1: string, o2: string) => (o1 && o2 ? o1 == o2 : false);

  constructor(
    private readonly message: MessageService,
    private readonly scopeChangeService: ScopeChangeService,
    public textShortnerHelper: TextShortnerHelper
  ) {}

  public isDeadLineDate(infos: AnalysisInformations | null): boolean {
    if (!infos) return false;

    let currentDate = moment(new Date().setHours(0, 0, 0, 0)).utcOffset(0,true);
    let deadLineDate = moment(infos.deadline).utcOffset(0, true);
    return currentDate > deadLineDate;
  }

  public getScopeChangeStatus(scopeChange: AnalysisInformations | null): string {
    return scopeChange?.status ? scopeChange.status === 'EAN' ? 'ANA' : scopeChange.status : "INI";
  }

  getUsersByRoleAndComplexId() {
    let callFunction = this.getUserList();
    if (callFunction != null) {
      callFunction.subscribe({
        next: (userList) => {
          this.usersSpecialist = userList
        }
      })
    }
  }

  startEditDiscipline(id, discipline): void {
    this.getUsersByRoleAndComplexId();
    this.editingRow = true;
    this.isEditing = true;
    this.editIdDiscipline = id;
    if (discipline !== null) {
      this.editDateDeadlineDiscipline =
        discipline.analysisDeadline + 'T00:00:00';
      this.selectedUserResponsibleIdDiscipline = discipline.userAssessment.id;
    }
    this.disciplineInEditMode = discipline;
  }

  startEdit(): void {
    this.editingRow = true;
    this.getUsersByRoleAndComplexId();
  }

  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) < 0;

  stopEdit() {
    this.editingRow = false;
  }

  updateScopeChangeUser(userId) {
    if (!this.scopeChangeId) { 
      this.changeValue.emit(userId) 
      this.editingRow = false;
      return;
    }

    if (
      this.scopeChange?.userAnalisis === null ||
      this.scopeChange?.userAnalisis.id !=
        this.selectedUserResponsibleIdDiscipline
    ) {
      //adjust the below code to check for null
      var request = this.buildPayload();
      this.scopeChangeService
        .updateUserAnalisis(this.scopeChangeId, request)
        .subscribe({
          next: (response) => {
            if (response) {
              this.message.showSucessMessage('Usuario atualizado com Sucesso');
              if (this.scopeChange) {
                this.scopeChange.userAnalisis = response.userAnalisis;
                this.changeValue.emit(response.userAnalisis);
              }
            }
          },
          error: (err) => {
            this.message.showErrorMessage('Erro ao alterar responsavel');
          },
        });
      this.editingRow = false;
    } else {
      this.editingRow = false;
    }
  }

  private buildPayload() {
    var request: ScopeChangeRequest = {
      areaId: this.scopeChange ? this.scopeChange?.area.id : 0,
      classificationType: this.scopeChange
        ? this.scopeChange?.classificationType
        : '',
      comments: this.scopeChange ? this.scopeChange?.comments : '',
      complexId: this.scopeChange ? this.scopeChange?.complex.id : 0,
      isDraft: false,
      operationalUnitId: this.scopeChange?.operationalUnit
        ? this.scopeChange?.operationalUnit.id
        : 0,
      projectCode: this.scopeChange ? this.scopeChange?.code : '',
      projectId: this.scopeChange ? this.scopeChange?.projectId : 0,
      regionId: this.scopeChange?.region ? this.scopeChange?.region.id : 0,
      scopeCategory: this.scopeChange?.scopeChangeReasons
        ? this.scopeChange?.scopeChangeReasons.map((ca) => ca.id)
        : [0],
      scopeDescription: this.scopeChange
        ? this.scopeChange?.impactDescription
        : '',
      userLegalRepId: this.scopeChange?.userLegalRepresentative
        ? this.scopeChange?.userLegalRepresentative.id
        : 0,
      userAnalisisId: this.selectedUserResponsibleIdDiscipline,
    };
    return request;
  }
}
