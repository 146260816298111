import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-non-editable-table',
  templateUrl: './generic-non-editable-table.component.html',
  styleUrls: ['./generic-non-editable-table.component.scss']
})
export class GenericNonEditableTableComponent implements OnInit {
  @Input() dataSource!: Array<{ [key: string]: any }>;
  @Input() tableTitle!: string;
  displayData: Array<{ [key: string]: any }> = [];
  columns: string[] = [];
  links: string[] = [];

  constructor() {}

  ngOnInit() {
    if (this.dataSource && this.dataSource.length > 0) {
      this.columns = Object.keys(this.dataSource[0]).filter(key => key !== 'Link');
      this.displayData = this.dataSource.map(({ link, ...rest}) => rest);
      this.links = this.dataSource.map(data => data['Link']);
    }
  }

  redirectToLink(index: number): void {
    const link = this.links[index];
    if (link) {
      window.open(link, '_blank');
    }
  }
}