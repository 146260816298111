import { Component } from "@angular/core";
import { Alert, AlertMessageService } from "src/app/shared/services/alert-message.service";

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent {
  public alerts: Alert[] = []

  constructor(private alertService: AlertMessageService) {
    alertService.alertSent$.subscribe(item => this.alertSent(item));
  }
  alertSent(alert: Alert) {
    alert.id = Date.now().toString();
    this.alerts.push(alert);

    setTimeout(() => {
      this.alerts.shift()
    }, 4000)
  }

  removeAlert(id: string | undefined) {
    if (id) {
      this.alerts = this.alerts.filter(alert => alert.id !== id);
    }
  }
}