import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-icon-custom',
  templateUrl: './svg-icon-custom.component.html',
  styleUrls: ['./svg-icon-custom.component.scss']
})
export class SvgIconCustomComponent {

  @Input() icon: string = '';
  @Input() width: string = '24';
  @Input() height: string = '24';
  @Input() viewBox: string = '0 0 24 24';
  @Input() fill: string = 'currentColor';
  @Input() className: string = '';

}
