import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-information-banner',
  templateUrl: './information-banner.component.html',
  styleUrls: ['./information-banner.component.scss'],
})
export class InformationBannerComponent {
  @Input() bannerText: string;
  @Input() bannerColor: 'yellow' | 'red' = 'yellow';
  @Input() iconName: string = 'icon_warning_orange.svg';
  @Input() colorText: 'yellow' | 'orange' = 'yellow';
}
