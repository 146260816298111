import { Component,  inject } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-cancel-process',
  templateUrl: './modal-cancel-process.component.html',
  styleUrls: ['./modal-cancel-process.component.scss']
})
export class ModalCancelProcessComponent {

  readonly #modal = inject(NzModalRef);
  
  onCancel(): void {
    this.#modal.close();
  }

  onHidenBanner(): void {
    this.#modal.triggerOk();
    this.#modal.close();
  }

}
