import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import {Observable} from 'rxjs'
import { StudyTypeModel } from '../models/study-type.model'

@Injectable({
  providedIn: 'root'
})
export class DemandService {
  baseUrl: string = environment.apiUrl;
  constructor(private readonly http: HttpClient) { }
  index = 0;

  getStudyTypes(): Observable<StudyTypeModel[]> {
    return this.http.get<StudyTypeModel[]>(`${this.baseUrl}/study-types`)
  }

}
