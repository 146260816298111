import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TextShortnerHelper {
  constructor() {}

  textShortner(text:string, maxChars:number){
    if (text !== null && text.length > maxChars) {
        return text.substring(0, maxChars - 3) + '...';
      }
    return text;
  }

  isTooBig(text:string, maxChars:number){
    return text !== null && text.length > maxChars;

  }
}
