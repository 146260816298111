import { Component } from '@angular/core';
@Component({
  selector: 'app-dashboard-estudos-ambientais',
  templateUrl: './dashboard-estudos-ambientais.component.html',
  styleUrls: ['./dashboard-estudos-ambientais.component.scss'],
})
export class DashboardEstudosAmbientaisComponent {


  constructor() {}

}
