import { Component, type OnInit } from '@angular/core';
import { ActivePageService } from 'src/app/shared/common/services/active-page/active-page.service';
import { DashboardsEnum } from '../../shared/enum/dashboards.enum';

import { TopBreadcrumbService } from 'src/app/shared/common/services/top-breadcrumb/top-breadcrumb.service';
import { ActivatedRoute } from '@angular/router';
import { RoutesEnum } from 'src/app/shared/enum/routes.enum';
import { setPages } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import { Page } from 'src/app/shared/ngrx/breadcrumb/pages.model';
import { BreadcrumbState } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';
import { Store } from '@ngrx/store';




@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.scss'],
})
export class DashboardsComponent implements OnInit {
  constructor(
    private activePage: ActivePageService,
    private topBreadcrumb: TopBreadcrumbService,
    private route: ActivatedRoute,
    private store: Store<BreadcrumbState>
  ) {}
  dashboardTypeLicensing: boolean = true;
  dashboardTypeEia: boolean = false;
  dashboardTypeManager: boolean = false;
  dashboardTypeSupplier: boolean = false;

  dashboardToDisplay: string = '';
  currentPage: string = '';
  dashboardNameToDisplayBreadcrumb;


  ngOnInit(): void {
    this.activePage.setPage('dashboards');

    this.dashboardTypeLicensing = true;
    this.dashboardNameToDisplayBreadcrumb = 'Licenciamento';
    this.updateBradcrumb();

    this.route.params.subscribe(params => {

      const type = params['type'];

      if(type === RoutesEnum.DASHBOARDS_TYPE_LICENSING){
        this.handleCheckClick("LIC");
        this.currentPage = "LIC";
      }
      if(type === RoutesEnum.DASHBOARDS_TYPE_STUDIES){
        this.handleCheckClick("EIA");
        this.currentPage = "EIA";
      }
      if(type === RoutesEnum.DASHBOARDS_TYPE_SUPPLIERS){
        this.handleCheckClick("SUP");
        this.currentPage = "SUP";
      }
      if(type === RoutesEnum.DASHBOARDS_TYPE_MANAGEMENT){
        this.handleCheckClick("MAN");
        this.currentPage = "MAN";
      }
    });

  }

  updateBradcrumb() {
    const pagesArray: Page[] = [
      {
        name: `Dashboards`,
        url: ''
      },
      {
        name: `${this.dashboardNameToDisplayBreadcrumb}`,
        url: ''
      },
    ];

    this.store.dispatch(setPages({pages: pagesArray}));
  }

  handleCheckClick(event) {
    this.dashboardTypeLicensing = false;
    this.dashboardTypeEia = false;
    this.dashboardTypeManager = false;
    this.dashboardTypeSupplier = false;
    if (event === DashboardsEnum.LIC) {
      this.dashboardTypeLicensing = true;
      this.dashboardNameToDisplayBreadcrumb = 'Licenciamento';
      this.dashboardToDisplay = 'ReportSectiondc0bdd83f3d0f9ff4729';
    } else if (event === DashboardsEnum.EIA) {
      this.dashboardTypeEia = true;
      this.dashboardNameToDisplayBreadcrumb = 'Estudos Ambientais';
      this.dashboardToDisplay = 'ReportSectiona271643cba2213c935be';
    } else if (event === DashboardsEnum.MAN) {
      this.dashboardTypeManager = true;
      this.dashboardNameToDisplayBreadcrumb = 'Gerencial';
      this.dashboardToDisplay = 'ReportSectiona271643cba2213c935be';
    } else if (event === DashboardsEnum.SUP) {
      this.dashboardTypeSupplier = true;
      this.dashboardNameToDisplayBreadcrumb = 'Fornecedores';
      this.dashboardToDisplay = 'ReportSection476aef7e0a344328dfe5';
    } else {
      this.dashboardTypeLicensing = true;
      this.dashboardNameToDisplayBreadcrumb = 'Licenciamento';
      this.dashboardToDisplay = 'ReportSectiondc0bdd83f3d0f9ff4729';
    }
    this.updateBradcrumb();
  }

}
