import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {ProductNlp} from "../interface/nlp-interface";
import {NLP_PRODUCT_API, NLP_RESULT_STATUS} from "../../projects/constants/apiEndpointsConstants";
import { HttpClient } from "@angular/common/http";
import {NlpResultStatusData} from "../interface/nlp-result-status";

@Injectable({
  providedIn: 'root'
})
export class ProductNlpService {

  constructor(private readonly http: HttpClient) { }

  getProductNlpById(id?:number): Observable<ProductNlp> {
    return  this.http.get<ProductNlp>(`${NLP_PRODUCT_API}/${id}`);
  }

  getNlpResultStatus(id?:number): Observable<NlpResultStatusData>{
    return  this.http.get<NlpResultStatusData>(`${NLP_RESULT_STATUS}/${id}`);
  }
}
