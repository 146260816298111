import { EventEmitter, Injectable } from "@angular/core";

type AlertTypes = "success" | "error" | "neutral"
export interface Alert {
  message: string,
  type: AlertTypes,
  id?: string
}

@Injectable({
  providedIn: 'root'
})
export class AlertMessageService {
  public alertSent$: EventEmitter<Alert>;

  constructor() {
    this.alertSent$ = new EventEmitter();
  }

  public send(message: string, type: AlertTypes) {
    const alert: Alert = { message, type };

    this.alertSent$.emit(alert)
  }
}