import pt from '@angular/common/locales/pt';
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe, CurrencyPipe, NgOptimizedImage, registerLocaleData } from '@angular/common';


import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { ProjectsService } from './modules/projects/projects.service';
import { ActivePageService } from './shared/common/services/active-page/active-page.service';
import { TopBreadcrumbService } from './shared/common/services/top-breadcrumb/top-breadcrumb.service';

import { AuthModule } from './core/auth/auth.module';
import { AppRoutingModule } from './app-routing.module';

import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NZ_I18N, pt_BR } from 'ng-zorro-antd/i18n';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzTimelineModule } from "ng-zorro-antd/timeline";
import { NzSkeletonModule } from "ng-zorro-antd/skeleton";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTreeSelectModule } from "ng-zorro-antd/tree-select";
import { NzInputNumberModule } from "ng-zorro-antd/input-number";
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzElementPatchModule } from "ng-zorro-antd/core/element-patch";

import { EmailValidatorHelper } from './shared/helpers/email-validator/email-validator.helper';
import { TextShortnerHelper } from './shared/components/text-shortner/text-shortner.helper';
import { FilterLastDaysHelper } from './shared/helpers/filter-last-days/filter-last-days.helper';
import { AppComponent } from './app.component';
import { NlpComponent } from './modules/nlp/nlp.component';
import { TasksComponent } from './modules/tasks/tasks.component';
import { TopbarComponent } from './core/shell/topbar/topbar.component';
import { AlertMessageComponent } from './core/shell/alert-message/alert-message.component';
import { SidebarComponent } from './core/shell/sidebar/sidebar.component';
import { TopmenuComponent } from './core/shell/topmenu/topmenu.component';
import { DocumentsComponent } from './modules/documents/documents.component';
import { DashboardsComponent } from './modules/dashboards/dashboards.component';
import { HelpCenterComponent } from './modules/help-center/help-center.component';
import { NlpReportComponent } from './modules/nlp/nlp-report/nlp-report.component';
import { BreadcrumbComponent } from './core/shell/breadcrumb/breadcrumb.component';
import { UserProfileComponent } from './modules/user-profile/user-profile.component';
import { NlpTopmenuComponent } from './modules/nlp/nlp-topmenu/nlp-topmenu.component';
import { NlpSidebarComponent } from './modules/nlp/nlp-sidebar/nlp-sidebar.component';
import { NlpWelcomeComponent } from './modules/nlp/nlp-welcome/nlp-welcome.component';
import { ButtonsLicensingModule } from './shared/components/buttons/buttons-licensing.module';
import { ProductsListComponent } from './modules/nlp/products-list/products-list.component';
import { LoadingComponent } from './shared/components/loading-modal/loading-modal.component';
import { NewTaskModalComponent } from './modules/tasks/new-task-modal/new-task-modal.component';
import { DocumentsListComponent } from './modules/documents/documents-list/documents-list.component';
import { NotificationsComponent } from './modules/notifications/notifications/notifications.component';
import { MenuDashboardsComponent } from './modules/dashboards/menu-dashboards/menu-dashboards.component';
import { NlpReferenceTermComponent } from './modules/nlp/nlp-reference-term/nlp-reference-term.component';
import { ResultBodyComponent } from './modules/nlp/nlp-analysis-result/result-body/result-body.component';
import { LoadingBarComponent } from './shared/components/loading-modal/loading-bar/loading-bar.component';
import { NlpAnalysisResultComponent } from './modules/nlp/nlp-analysis-result/nlp-analysis-result.component';
import { CopyLinkModalComponent } from './modules/documents/shared/copy-link-modal/copy-link-modal.component';
import { CardSidebarComponent } from './modules/nlp/nlp-sidebar/component/card-sidebar/card-sidebar.component';
import { CommentModalComponent } from './modules/documents/documents-list/comment-modal/comment-modal.component';
import { AllNotificationsComponent } from './modules/notifications/all-notifications/all-notifications.component';
import { NlpIndicatorsComponent } from './modules/nlp/nlp-analysis-result/nlp-indicators/nlp-indicators.component';
import { DeleteFileModalComponent } from './modules/documents/shared/delete-file-modal/delete-file-modal.component';
import { RestoreFileModalComponent } from './modules/documents/shared/restore-file-modal/restore-file-modal.component';
import { DeleteSectionModalComponent } from './modules/nlp/shared/delete-section-modal/delete-section-modal.component';
import { HistoricElementComponent } from './modules/documents/documents-list/historic-element/historic-element.component';
import { FilterNotificationsComponent } from './modules/notifications/filter-notifications/filter-notifications.component';
import { NlpAddProjInformationsComponent } from './modules/nlp/nlp-add-proj-informations/nlp-add-proj-informations.component';
import { DashboardGerencialComponent } from './modules/dashboards/component/dashboard-gerencial/dashboard-gerencial.component';
import { DashboardLicensingComponent } from './modules/dashboards/component/dashboard-licensing/dashboard-licensing.component';
import { ItemsToVerifyComponent } from './modules/nlp/nlp-analysis-result/result-body/items-to-verify/items-to-verify.component';
import { MentionsNotificationsComponent } from './modules/notifications/mentions-notifications/mentions-notifications.component';
import { AssignedNotificationsComponent } from './modules/notifications/assigned-notifications/assigned-notifications.component';
import { DashboardFornecedoresComponent } from './modules/dashboards/component/dashboard-fornecedores/dashboard-fornecedores.component';
import { NewLicensingProcessComponent } from './modules/projects/projects-licensing/new-project-licensing/new-project-licensing.component';
import { DocumentsTableGroupComponent } from './modules/documents/documents-list/documents-table-grouped/documents-table-grouped.component';
import { NlpModalUpdateTRResultComponent } from './modules/nlp/nlp-analysis-result/nlp-modal-update-tr-result/nlp-modal-update-tr-result.component';
import { NlpContentReferenceTermComponent } from './modules/nlp/nlp-reference-term/nlp-content-reference-term/nlp-content-reference-term.component';
import { NlpModalEvaluateSectionComponent } from './modules/nlp/nlp-analysis-result/nlp-modal-evaluate-section/nlp-modal-evaluate-section.component';
import { ProjectStatusLegendComponent } from './modules/projects/projects-licensing/components/project-status-legend/project-status-legend.component';
import { DashboardEstudosAmbientaisComponent } from './modules/dashboards/component/dashboard-estudos-ambientais/dashboard-estudos-ambientais.component';
import { NlpModalSelectTermReferenceComponent } from './modules/nlp/nlp-reference-term/nlp-modal-select-term-reference/nlp-modal-select-term-reference.component';
import { NewProjectLicensingForm } from './modules/projects/projects-licensing/new-project-licensing/new-project-licensing-form/new-project-licensing-form.component';
import { LinkedProcessesListComponent } from './modules/projects/projects-licensing/components/linked-processes/linked-processes-list/linked-processes-list.component';
import { LicensingFormItem } from './modules/projects/projects-licensing/new-project-licensing/new-project-licensing-form/licensing-form-item/licensing-form-item.component';
import { NlpModalNewSectionTermReferenceComponent } from './modules/nlp/nlp-reference-term/nlp-modal-new-section-term-reference/nlp-modal-new-section-term-reference.component';
import { FormNewLinkedProcessesComponent } from './modules/projects/projects-licensing/components/linked-processes/form-new-linked-processes/form-new-linked-processes.component';
import { ButtonAddLinkedProcessComponent } from './modules/projects/projects-licensing/components/linked-processes/button-add-linked-process/button-add-linked-process.component';
import { LinkedProcessesContainerComponent } from './modules/projects/projects-licensing/components/linked-processes/linked-processes-container/linked-processes-container.component';
import { NewActivityComponent } from './modules/projects/projects-licensing/components/activities/new-activity/new-activity.component';
import { ListProcessEditComponent } from './modules/projects/projects-licensing/components/process/list-process-edit/list-process-edit.component';
import { CommunicationsContainerComponent } from './modules/projects/projects-licensing/components/communications/communications-container/communications-container.component';
import { ClickOutsideDirective } from './shared/helpers/click-outside.directive';
import { REDUCER } from './shared/ngrx/breadcrumb/constants';
import { breadcrumbReducer } from './shared/ngrx/breadcrumb/breadcrumb.reducer';
import { VideoModalComponent } from './modules/help-center/video-modal/video-modal.component';
import { ActivityStudyItemsComponent } from './modules/projects/projects-licensing/components/activities/activity-study-items/activity-study-items.component';
import { SharedModule } from './shared/shared.module';
import { DotConverterPipe } from "./shared/pipe/dot-converter.pipe";
import { PositiveNumberDirective } from './shared/common/positive-number';
import { SimpleLayoutComponent } from './shared/components/templates/simple-layout/simple-layout.component';
import { SuspensionCancellationModule } from './modules/projects-v2/modal/suspension-cancellation-modal/suspension-cancellation.module';
import {SelectsLicensingModule} from "./shared/components/selects/selects-licensing.module";
import {InputsLicensingModule} from "./shared/components/inputs/inputs-licensing.module";
import { AnalysisBannerComponent } from './modules/smart-docs/components/smart-docs-analysis-view/analysis-banner/analysis-banner.component';
import { EnvLicensingOpinionSummaryComponent } from './modules/projects-v2/project-v2-suspension/component/env-licensing-opinion-summary/env-licensing-opinion-summary.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { CurrencyMaskModule } from 'ng2-currency-mask';

import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';

import { LicensingProcessComponent } from './modules/projects-v2/licensing-process/licensing-process.component';
import { CommunicationsModule } from './modules/communications/communications.module';
import { ModalCancelProcessComponent } from './modules/projects-v2/components/licensing-process/modal-cancel-process/modal-cancel-process.component';
import { DropdownModule } from "primeng/dropdown";
import { MultiSelectModule } from "primeng/multiselect";

registerLocaleData(pt);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
        AppComponent,
        ClickOutsideDirective,
        TopmenuComponent,
        TopbarComponent,
        AlertMessageComponent,
        SidebarComponent,
        NlpComponent,
        NlpTopmenuComponent,
        NlpReferenceTermComponent,
        NlpAnalysisResultComponent,
        NlpReportComponent,
        CardSidebarComponent,
        NlpAddProjInformationsComponent,
        NlpModalNewSectionTermReferenceComponent,
        NlpModalSelectTermReferenceComponent,
        NlpModalUpdateTRResultComponent,
        NlpContentReferenceTermComponent,
        NlpModalSelectTermReferenceComponent,
        NlpWelcomeComponent,
        NlpModalSelectTermReferenceComponent,
        NlpSidebarComponent,
        ResultBodyComponent,
        NlpIndicatorsComponent,
        NlpModalEvaluateSectionComponent,
        NlpModalSelectTermReferenceComponent,
        ResultBodyComponent,
        DocumentsComponent,
        DocumentsListComponent,
        ProductsListComponent,
        DocumentsTableGroupComponent,
        HistoricElementComponent,
        RestoreFileModalComponent,
        DeleteFileModalComponent,
        CopyLinkModalComponent,
        CommentModalComponent,
        ProjectStatusLegendComponent,
        ItemsToVerifyComponent,
        DeleteSectionModalComponent,
        DashboardsComponent,
        MenuDashboardsComponent,
        UserProfileComponent,
        DashboardLicensingComponent,
        DashboardGerencialComponent,
        DashboardFornecedoresComponent,
        DashboardEstudosAmbientaisComponent,
        NewTaskModalComponent,
        TasksComponent,
        NewLicensingProcessComponent,
        NewProjectLicensingForm,
        LicensingFormItem,
        FilterNotificationsComponent,
        NotificationsComponent,
        AllNotificationsComponent,
        MentionsNotificationsComponent,
        AssignedNotificationsComponent,
        LoadingBarComponent,
        LoadingComponent,
        LinkedProcessesContainerComponent,
        FormNewLinkedProcessesComponent,
        LinkedProcessesListComponent,
        ButtonAddLinkedProcessComponent,
        NewActivityComponent,
        ListProcessEditComponent,
        CommunicationsContainerComponent,
        BreadcrumbComponent,
        HelpCenterComponent,
        VideoModalComponent,
        ActivityStudyItemsComponent,
        SimpleLayoutComponent,
        LicensingProcessComponent,
        ModalCancelProcessComponent,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    imports: [
        BrowserModule,
        AppRoutingModule,

        ButtonModule,
        TooltipModule,
        OverlayPanelModule,
        TableModule,
        InputTextModule,
        ProgressSpinnerModule,
        DropdownModule,
        MultiSelectModule,
        DialogModule,

        CurrencyMaskModule,
        FormsModule,
        NzTagModule,
        NzButtonModule,
        NzCardModule,
        NzLayoutModule,
        NzMenuModule,
        NzDividerModule,
        NzDropDownModule,
        CommunicationsModule,
        ButtonsLicensingModule,
        AuthModule,
        NgxPermissionsModule.forRoot(),
        NzGridModule,
        NzInputModule,
        NzAlertModule,
        NzIconModule,
        NzSelectModule,
        NzSpaceModule,
        NzStepsModule,
        NzListModule,
        NzPaginationModule,
        NzModalModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        NzDatePickerModule,
        NzFormModule,
        NzCollapseModule,
        NzPopoverModule,
        NzTableModule,
        NzRadioModule,
        NzTabsModule,
        ReactiveFormsModule,
        NzDrawerModule,
        NgOptimizedImage,
        NzToolTipModule,
        NzSwitchModule,
        NzBreadCrumbModule,
        NzSpinModule,
        NzProgressModule,
        NzInputNumberModule,
        NzCheckboxModule,
        NzAutocompleteModule,
        NzDescriptionsModule,
        NzEmptyModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NzTimelineModule,
        NzSkeletonModule,
        NzPopconfirmModule,
        NzElementPatchModule,
        StoreModule.forRoot({breadcrumb : breadcrumbReducer}),
        StoreDevtoolsModule.instrument({
            name: 'SMART-LICENSING',
            maxAge: 25,
            logOnly: true,
            connectInZone: true
        }),
        NzElementPatchModule,
        NzTreeSelectModule,
        SharedModule,
        SuspensionCancellationModule,
        SelectsLicensingModule,
        InputsLicensingModule,
        NgxMaskDirective,
        NgxMaskPipe], providers: [
        { provide: NZ_I18N, useValue: pt_BR },
        { provide: LOCALE_ID, useValue: 'pt' },
        /*{
          provide: HTTP_INTERCEPTORS,
          useClass: NotFoundInterceptor,
          multi: true,
        },*/
        NzMessageService,
        ProjectsService,
        ActivePageService,
        TopBreadcrumbService,
        CurrencyPipe,
        DatePipe,
        DotConverterPipe,
        EmailValidatorHelper,
        FilterLastDaysHelper,
        TextShortnerHelper,
        PositiveNumberDirective,
        provideNgxMask(),
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
