import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges} from '@angular/core';
import {NzUploadFile} from 'ng-zorro-antd/upload';
import {AttachmentsService} from '../../services/attachments.service';
import {UtilService} from '../../util.service';
import {MessageService} from '../../common/services/message.service';
import {AttachmentsModel} from '../../models/attachments.model';
import {Observable, catchError, map, of} from 'rxjs';
import {NzModalService} from 'ng-zorro-antd/modal';
import {FileValidationService} from "../../services/file-validation.service";

@Component({
  selector: 'app-upload-files-horz',
  templateUrl: './upload-files-horz.component.html',
  styleUrls: ['./upload-files-horz.component.scss']
})
export class UploadFilesHorzComponent implements OnInit, OnChanges {
  @Output() reload: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() data?: any;
  @Input() disabledBlock?: boolean = false;
  @Input() hideDrag?: boolean = false;
  @Input() displayType?: 'ROW' | 'COLUMN' = 'ROW';
  @Input() maxHeightList?: number = 10; // in vh
  @Input() attachmentsId: number;
  @Input() entityName!: string;

  loading = false;
  fileList: NzUploadFile[] = [];
  fileListAlreadyUploaded: NzUploadFile[] = [];
  filesToDelete: string[] = [];
  fileListDuplicated: NzUploadFile[] = [];
  fileLisToSave: NzUploadFile[] = [];
  cadActivityInsert: boolean = false;
  isInvalidFile: boolean = false;

  constructor(private attachmentsService: AttachmentsService,
              private utilService: UtilService,
              private message: MessageService,
              private el: ElementRef,
              private renderer: Renderer2,
              private readonly modal: NzModalService,
              private fileValidationService: FileValidationService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['attachmentsId']) {
      this.getAttachmentFiles();
    }
  }

  ngOnInit() {
    this.cadActivityInsert = this.utilService.getAuthorization('insert', 'CADACTIVITY');
    this.renderer.setStyle(this.el.nativeElement, '--max-height-list', `${this.maxHeightList}vh`);
    this.getAttachmentFiles();
  }

  async verifyIfIsValidFile(file) {

    await this.fileValidationService.isExeFile(file).then(result => {
      if (result === true) {
        this.isInvalidFile = true
        this.message.showErrorMessage(`Tipo de arquivo ${file.name} não é suportado!`);
      }
    })
    await this.fileValidationService.isZipFile(file).then(result => {
      if (result === true) {
        this.isInvalidFile = true
        this.message.showErrorMessage(`Conteúdo ou extensão do arquivo ${file.name} não é suportada!`);
      }
    })
  }

  beforeUpload = (file: NzUploadFile): boolean => {

    this.verifyIfIsValidFile(file).then(result => {
      if( this.isInvalidFile === false){
        if(this.fileValidationService.isExtensionForbidden(file.name)){
          this.message.showErrorMessage(`Extenção do arquivo ${file.name} não é suportada!`);
        } else {
          if (this.checkFileNameLength(file) && this.checkFileSize(file)) {
            this.loading = true;
            const attachment = {
              file: file,
              name: file.name,
              entityId: this.attachmentsId,
              entityName: this.entityName,
              contextInfo: {
                phase: 'Cadastro'
              }, // Se necessário, preencha com informações adicionais
            };
            this.attachmentsService.uploadAttachment(attachment).subscribe({
              next: (response: AttachmentsModel) => {
                this.getAttachmentFiles();
                this.loading = false;
              },
              error: () => {
                this.loading = false;
                this.message.showErrorMessage(`Erro ao fazer upload do arquivo ${file.name}!`);
              }
            })
          }
        }
      } else {
        this.isInvalidFile = false
      }
    })
     return (this.isInvalidFile === true) ? false : true
  };

  removeFiles = (file: NzUploadFile): boolean => {
    this.filesToDelete.push(file.uid);
    this.fileList = this.fileList.filter((item) => item !== file);
    this.fileListDuplicated = this.fileListDuplicated.filter(
      (item) => item !== file
    );
    return false;
  };

  downloadFile(file, name) {
    if (!file) {
      return;
    }

    if (file.uid) {
      this.attachmentsService.getDownloadFile(+file.uid).subscribe({
        next: (resp) => this.attachmentsService.downloadFile(resp, file.name),
      });
    } else {
      this.attachmentsService.getDownloadFile(file).subscribe({
        next: (resp) => this.attachmentsService.downloadFile(resp, name),
      });
    }
  };

  checkFileNameLength(file: NzUploadFile): boolean {
    //move to an helper?
    if (file.name.length > 200) {
      this.message.showErrorMessage('Nome do arquivo muito grande. Máximo é 200 caracteres.');
      return false;
    }
    return true;
  }

  checkFileSize(file: NzUploadFile): boolean {
    //move to an helper?
    if (file && file.size && file.size > 400 * 1024 * 1024) {
      this.message.showErrorMessage('O arquivo é muito grande. Por favor, selecione um arquivo com menos de 350 MB.');
      return false;
    }
    return true;
  }

  getAttachment() {
    this.attachmentsService
    .getAttachmentsByEntityAndEntityId(
      this.attachmentsId,
      this.entityName
    )
    .subscribe((data) => {
      const nzUploadFiles = this.buildNzUploadFileArrayFromResponse(data);
      this.fileList = nzUploadFiles;
      this.fileListAlreadyUploaded = nzUploadFiles;
    });
  }

  private buildNzUploadFileArrayFromResponse(data: AttachmentsModel[]) {
    const files: NzUploadFile[] = [];
    for (const image of data) {
      const file: NzUploadFile = {
        description: image.description,
        name: image.name,
        filename: image.name,
        uid: image.id?.toString() || '',
        thumbUrl: this.utilService.getIconFromFileName(image.name),
        status: 'done',
      };
      files.push(file);
    }
    return files;
  }

  deleteFile(id) {
    this.modal.confirm({
      ...(this.message.showMessageModalConfirmDelete() as any),
      nzOnOk: () => {
        this.attachmentsService
        .deleteAttachment(id)
        .subscribe({
          next: () => {
            this.message.showSucessMessage('Arquivo deletado com sucesso');
          },
          complete: () => {
            this.getAttachmentFiles();
          },
          error: () => {
            this.message.showErrorMessage('Erro ao deletar arquivo');
          },
        });
      },
    });
  }

  onChangeFile(fileEvent) {
  }

  getAttachmentFiles() {
    this.loading = true;
    if (this.attachmentsId) {
      this.attachmentsService
      .getAttachmentsByEntityAndEntityId(this.attachmentsId, this.entityName)
      .subscribe({
        next: (files) => {
          const nzUploadFiles = this.buildNzUploadFileArrayFromResponse(files);
          this.fileList = nzUploadFiles;
          this.fileListAlreadyUploaded = nzUploadFiles;
          this.data = files;
          this.loading = false;
        },
        error: () => {
          this.data = []
          this.fileList = [];
          this.fileListAlreadyUploaded = [];
          this.loading = false
        },
      });
    }
  }

  directUpload = (file: NzUploadFile): Observable<string> => {
    // REFATORAR QUANDO FOR PRA GUARDAR EM MEMORIA E DEPOUS ENVIAR
    this.loading = true;
    const attachment = {
      file: file,
      name: file.name,
      entityId: this.attachmentsId,
      entityName: this.entityName,
      contextInfo: {
        phase: 'Cadastro'
      }, // Se necessário, preencha com informações adicionais
    };
    return this.attachmentsService.uploadAttachment(attachment).pipe(
      map((response: AttachmentsModel) => {
        this.getAttachmentFiles();
        this.loading = false;
        return '';
      }),
      catchError(() => {
        this.loading = false;
        return of(''); // Retorne uma string vazia em caso de erro
      })
    );
  }

}
