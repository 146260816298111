import {Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {COMMUNICATION_TYPES_API} from "../../modules/projects/constants/apiEndpointsConstants";
import {CommunicationTypesResponseDto} from "../dto/communication-types-response.dto";

@Injectable({
  providedIn: 'root'
})
export class CommunicationTypeService {
  constructor(private readonly http: HttpClient) { }

  getCommunicationByCategory(category: string, statusProcess?: string): Observable<CommunicationTypesResponseDto[]> {
    let status = '';
    if(statusProcess) {
      status = `?status=${statusProcess}`
    }

    return this.http.get<CommunicationTypesResponseDto[]>(`${COMMUNICATION_TYPES_API}/${category}${status}`)
  }
}
