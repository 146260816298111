import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard-gerencial',
  templateUrl: './dashboard-gerencial.component.html',
  styleUrls: ['./dashboard-gerencial.component.scss'],
})
export class DashboardGerencialComponent {

  constructor() {}
  
}
